import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { PropTypes as mobxPropTypes } from 'mobx-react'
import { DropdownList } from 'react-widgets'
import $ from 'jquery'
import { hasThisOption, mapFilterKeyToParamKey, handleOnChange } from './helpers'
import TopNavBar from '../../navbar/TopNavBar'
import NavBarItem from '../../navbar/NavBarItem'
import DropdownItem from './DropdownItem'
import styles from './index.module.scss'
import { defaultOptions } from '../stores/IncidentReportFilterStore/const'
import { GroupQuickFilterContainer } from '../../ui/GroupQuickFilterWithGroupStore'

export const IncidentFilterBar = ({
  query,
  queryCriteria,
  setQueryFromURL,
  history,
  filters,
  sortBy,
  groupBy,
}) => {
  useEffect(() => {
    if (!$.isEmptyObject(query)) setQueryFromURL(query)
    else
      history.replace({
        pathname: `/incidents`,
        search: '?' + $.param(defaultOptions),
      })
  }, [history, query, setQueryFromURL])

  const groupAndSortOptions = { groupBy, sortBy }

  const renderDropdown = filterOptions =>
    Object.entries(filterOptions).map(([key, options]) => (
      <li className={styles.filterListWrapper} key={key}>
        <ul className={styles.list}>
          <NavBarItem className={styles.listItem}>
            <DropdownList
              className={styles.rwDropdownOverrides}
              data={options}
              valueField="value"
              textField="name"
              value={
                hasThisOption(queryCriteria[mapFilterKeyToParamKey(key)], options) ||
                options[0]
              }
              onChange={val =>
                handleOnChange(key, val, queryCriteria, history.push)
              }
              valueComponent={DropdownItem}
              data-cy="incident-filter-by"
            />
          </NavBarItem>
        </ul>
      </li>
    ))
  return (
    <>
      <TopNavBar filterBar>
        <div className={styles.filterBy}>
          {!query.showFilters && (
            <GroupQuickFilterContainer useTopNav={false} />
          )}
          {renderDropdown(filters)}
        </div>
        {<div className={styles.spacer} />}
        <div className={styles.groupBy} data-cy="incident-group-by">
          {renderDropdown(groupAndSortOptions)}
        </div>
      </TopNavBar>
    </>
  )
}

IncidentFilterBar.propTypes = {
  query: PropTypes.shape({
    showFilters: PropTypes.string,
  }),
  queryCriteria: PropTypes.shape({}),
  setQueryFromURL: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
  }).isRequired,
  filters: PropTypes.shape({}).isRequired,
  sortBy: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({}))
    .isRequired,
  groupBy: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({}))
    .isRequired,
}

IncidentFilterBar.defaultProps = {
  query: {},
  queryCriteria: {},
  hasFlag: false,
}

IncidentFilterBar.displayName = 'IncidentFilterBar'

export default IncidentFilterBar
